import Formation from "../../models/Formation";

import FormationsManager from "../formations-manager.webp";

const formationsData: Formation[] = [
  {
    image: FormationsManager,
    title: "Formation : Manager, organiser le travail de son équipe",
    description:
      "Au cœur de cette formation, vous découvrirez une immersion complète dans les fondamentaux du management, vous dotant des compétences essentielles pour diriger avec succès et efficacité.",
    id: "formation-manager",
    content: (
      <div>
        <p>
          Une bonne organisation du travail permet de faire avancer les projets
          dans un climat serein. La planification et le suivi des activités est
          un levier de motivation et de productivité important. Cette formation
          vous apporte des réponses opérationnelles pour évaluer les
          compétences, le potentiel de votre équipe et vous permet de mieux
          gérer les priorités au quotidien
        </p>
        <h2>Public Visé</h2>
        <p>
          Les managers, ou responsables d’équipe en poste ou futurs managers
        </p>
        <h2>Objectifs pédagogiques</h2>
        <p>À l’issue de la formation, le participant sera en mesure de :</p>
        <ul>
          <li>Organiser son temps et celui de son équipe</li>
          <li>
            Faire le point sur le niveau de priorité et d'urgence des activités
          </li>
          <li>Piloter et suivre le travail de son équipe</li>
          <li>Utiliser la communication assertive et savoir dire non</li>
          <li>Faire adhérer aux décisions et changements</li>
          <li>Gérer une relation conflictuelle au sein de l'équipe</li>
        </ul>

        <h2>Durée et modalité d’organisation</h2>
        <ul>
          <li>Dates : Définies selon planning</li>
          <li>
            Durée: 2 jours de 9 h00 à 17h, soit 7 heures effectives de formation
            par jour
          </li>
          <li>
            Durée: A distance, avec ou sans regroupements, en partie à distance
            et en partie en présentiel
          </li>
          <li>
            Organisation : A distance, avec ou sans regroupements, en partie à
            distance et en partie en présentiel
          </li>
          <li>En intra ou inter entreprise</li>
          <li>Taille du groupe : jusqu’à 10 stagiaires</li>
        </ul>
        <h2>Lieux</h2>

        <ul>
          <li>En intra : Chez le client</li>
          <li>En inter : Des salles de formation sont proposées à Paris</li>
        </ul>

        <h2>Accessibilité </h2>
        <p>
          Pour toute situation de handicap merci de nous contacter pour
          envisager la faisabilité
        </p>

        <h2>Délai et modalités d'accès</h2>

        <p>
          Jusqu'à 48h avant le début de la formation et en fonction des
          plannings
        </p>

        <h2>Tarifs</h2>
        <p>
          1.200 Euros hors taxes pour les 2 jours de formation par stagiaire
        </p>

        <h2>Prérequis</h2>
        <p>
          Aucun prérequis excepté une expérience de travail minimum de 2 ans
        </p>

        <h2>Programme de la formation</h2>
        <h3>Comprendre le fonctionnement d'un groupe au travail</h3>
        <ul>
          <li>Analyser la dynamique de groupe.</li>
          <li>Appréhender les attentes et besoins du groupe.</li>
          <li>Situer le rôle de l'équipe dans l'entreprise.</li>
          <li>Positionner chaque individu dans le fonctionnement du groupe.</li>
          <li>Identifier les capacités de chaque collaborateur.</li>
          <li>Collecter les critères de réussite du travail en groupe.</li>
          <li>Identifier et gérer les différentes personnalités.</li>
          <li>Faire partager sa vision et les valeurs de l'entreprise.</li>
        </ul>
        <h3>Travaux pratiques</h3>
        <p>
          Jeu de présentation du groupe, réflexion sur la dynamique créée et les
          bénéfices du travail en groupe.
        </p>
        <h3>Faire le bilan sur sa propre gestion du temps</h3>
        <ul>
          <li>Mesurer sa perception et sa relation au temps.</li>
          <li>Représenter l'utilisation de son temps.</li>
          <li>
            Réfléchir sur les points forts de son organisation et aux pertes de
            temps.
          </li>
          <li>
            Quantifier le temps passé en réunion, au téléphone, en RDV, en
            déplacement...
          </li>
          <li>
            Élaborer ses nouveaux objectifs et définir ses points de progrès.
          </li>
        </ul>
        <h3>Travaux pratiques</h3>
        <p>
          Évaluation de sa relation au temps à l'aide d'un auto-positionnement
        </p>
        <h3>Organiser le travail collectif</h3>
        <ul>
          <li>Identifier les temps de travail collectifs actuels.</li>
          <li>Répertorier les missions et tâches de son équipe.</li>
          <li>
            Identifier les résistances rencontrées dans l'optimisation du temps.
          </li>
          <li>Analyser les compétences des équipes.</li>
          <li>
            Évaluer la motivation des collaborateurs pour la répartition des
            activités.
          </li>
          <li>
            Prendre en compte les besoins individuels et collectifs dans
            l'organisation des activités.
          </li>
          <li>
            Adapter son management au niveau de maturité des collaborateurs.
          </li>
          <li>
            Évaluer le profil relationnel de ses collaborateurs pour gagner du
            temps dans les échanges.
          </li>
          <li>Mettre en place un plan de délégation efficace.</li>
        </ul>
        <h3>Travaux pratiques</h3>
        <p>
          Répertorier les missions et les tâches de son équipe. Élaborer un plan
          de délégation.
        </p>
        <h3>Organiser le travail collectif</h3>
        <ul>
          <li>
            Classer les activités selon le principe d'Eisenhower : important et
            urgent.
          </li>
          <li>Faire le point sur le niveau prioritaire des activités.</li>
          <li>Déterminer le temps des tâches de ses collaborateurs.</li>
          <li>S'approprier les règles d'une organisation efficace.</li>
          <li>Utiliser les outils de la planification.</li>
          <li>
            Savoir organiser les tâches de ses collaborateurs dans le temps.
          </li>
        </ul>

        <h3>Travaux pratiques</h3>
        <p>
          Définir ses priorités avec la matrice d'Eisenhower, puis débriefing et
          atelier de coaching
        </p>
        <h3>Piloter et suivre l'activité de son équipe</h3>
        <ul>
          <li>Définir les règles de l'équipe en termes de suivi</li>
          <li>
            Fixer des objectifs collectifs et individuels avec la méthode SMART
          </li>
          <li>Piloter avec des tableaux de bord</li>
          <li>Choisir des indicateurs de suivi</li>
          <li>
            Définir et formaliser des plans d'actions individuels et collectifs
          </li>
          <li>
            Suivre les actions dans le temps : mettre en place les entretiens de
            suivi
          </li>
          <li>
            Formuler des feedbacks constructifs et des signes de reconnaissance
          </li>
          <li>Encourager sur les résultats obtenus et à atteindre</li>
        </ul>

        <h3>Travaux pratiques</h3>
        <p>
          Construction en sous-groupes d'un plan d'actions avec des objectifs
          SMART
        </p>
        <h3>Évaluer l'impact du changement sur l'organisation de l'activité</h3>
        <ul>
          <li>Comprendre les étapes de l'acceptation au changement.</li>
          <li>Savoir communiquer pour préparer le changement.</li>
          <li>Comprendre les réactions de l'équipe face aux changements.</li>
          <li>
            Évaluer l'impact du changement sur l'organisation de l'activité.
          </li>
          <li>Faire adhérer l'équipe au changement.</li>
          <li>
            Améliorer sa capacité à expliquer et à donner du sens aux décisions
            prises.
          </li>
          <li>Agir pour maintenir l'implication dans la durée.</li>
        </ul>

        <h3>Travaux pratiques</h3>
        <p>
          Jeux de rôles : communiquer et faire adhérer au changement. Exercice
          pratique sur les freins et les solutions à proposer.
        </p>
        <h3>Organiser sa communication managériale</h3>
        <ul>
          <li>Connaître les règles de base de la communication.</li>
          <li>
            Utiliser les différentes formes de communication : digitale,
            réunion, entretien...
          </li>
          <li>
            Utiliser le management situationnel pour adapter sa communication.
          </li>
          <li>Organiser des temps d'échanges collectifs et individuels.</li>
          <li>
            Utiliser la communication assertive pour renforcer son leadership.
          </li>
          <li>Écouter activement, questionner, savoir reformuler.</li>
          <li>
            Utiliser l'empathie comme source de compréhension de soi et des
            autres.
          </li>
          <li>Gérer les sollicitations et interruptions.</li>
          <li>Savoir formuler ses demandes. Savoir dire "non".</li>
        </ul>

        <h3>Travaux pratiques</h3>
        <p>
          Mises en situation filmées en utilisant la fonction vidéo du téléphone
          autour de la communication : savoir dire non, être assertif, pratiquer
          l'écoute active et le questionnement... Débriefing en groupe.
        </p>
        <h3>
          Organiser les relations de l'équipe au quotidien : gérer les tensions
        </h3>
        <ul>
          <li>
            Désamorcer les tensions. Les trois cerveaux : à la base de nos
            réactions.
          </li>
          <li>Comprendre et gérer ses réactions.</li>
          <li>Comprendre les mécanismes du triangle dramatique.</li>
          <li>Comprendre les comportements et y faire face.</li>
          <li>Gérer ses émotions et pensées négatives.</li>
          <li>
            Canaliser ses propres tensions ainsi que celles de ses
            collaborateurs.
          </li>
          <li>Maîtriser les étapes de résolution des désaccords.</li>
          <li>Anticiper les situations et relations difficiles.</li>
        </ul>
        <h3>Travaux pratiques</h3>
        <p>
          Jeux de rôles filmés sur la gestion de la relation conflictuelle, et
          atelier de coaching sur des problématiques factuelles des participants
        </p>
        <h2>Modalités pratiques</h2>
        <h3>Travaux pratiques</h3>
        <p>
          Partage d'expériences. Mises en situation filmées puis analysées, jeux
          de rôles, test et autodiagnostics, études de cas...
        </p>
        <h2>Modalités d'évaluation</h2>
        <h3>Travaux pratiques</h3>
        <p>
          Le formateur évalue la progression pédagogique du participant tout au
          long de la formation au moyen de QCM, mises en situation, travaux
          pratiques…
        </p>
        <p>
          Le participant complète également un test de positionnement en amont
          et en aval pour valider les compétences acquises
        </p>
      </div>
    )
  }
];

export default formationsData;
