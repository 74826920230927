import React from "react";
import clsx from "clsx";

import "./styles.scss";

export interface InputProps {
  label: string;
  placeholder: string;
  type: string;
  isTextArea?: boolean;
  className?: string;

  value: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const Input: React.FC<InputProps> = ({
  label,
  placeholder,
  type,
  isTextArea = false,
  className,
  value,
  onChange
}) => {
  const baseClass = "ui-input";

  const rootClass = clsx(baseClass, className);

  return (
    <div className={rootClass}>
      <label className="ui-input-label">{label}</label>
      {!isTextArea ? (
        <input
          type={type}
          className="ui-input-input"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      ) : (
        <textarea
          rows={8}
          className="ui-input-text-area"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        ></textarea>
      )}
    </div>
  );
};

export default Input;
