import React from "react";
import "./styles.scss";
import Formation from "../../models/Formation";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import * as Scroll from "react-scroll";

const FormationCell: React.FC<Formation & { type?: string }> = ({
  image,
  title,
  description,
  id,
  type
}) => {
  const navigate = useNavigate();

  return (
    <div className="formation-cell">
      <img
        className="responsive formation-cell-image"
        src={image}
        alt={title}
      />
      <div className="formation-cell-texts">
        <h4>{title}</h4>
        <p>{description}</p>
        {id && type && (
          <>
            <Button
              onClick={() => navigate(`/${type}/${id}`)}
              className="formation-cell-button"
            >
              Programme de formation
            </Button>
            <Button
              outline
              onClick={() => {
                Scroll.scroller.scrollTo("optimiser", {
                  offset: -60,
                  smooth: true
                });
              }}
              className="formation-cell-button"
            >
              En savoir plus sur les financements
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default FormationCell;
