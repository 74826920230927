import Formation from "../../models/Formation";
import Coaching from "../coaching-coaching.webp";

const bilansData: Formation[] = [
  {
    image: Coaching,
    title: "Coaching",
    description:
      "Le coaching professionnel est un processus interactif et individualisé au cours duquel un coach qualifié guide et soutient une personne (le coaché) dans le développement de ses compétences"
  }
];

export default bilansData;
