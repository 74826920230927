import React from "react";
import "./styles.scss";
import formationsData from "../../../assets/data/formations";

import FormationCell from "../../../components/FormationCell";

const FormationsSection: React.FC = () => {
  return (
    <section className="formations-section" id="formations">
      <h2>Nos formations</h2>
      <p>
        Nos formations d'experts, animées par des praticiens chevronnés, vous
        ouvrent les portes d'un monde de compétences. En petits groupes, nos
        formations en gestion transversale assurent une expérience immersive et
        opérationnelle pour votre carrière. Nos formateurs, axés sur une écoute
        bienveillante, personnalisent chaque apprentissage pour répondre à vos
        besoins. Nos programmes constamment actualisés abordent les défis
        contemporains avec des cas pratiques et des mises en situation réelles,
        renforcés par des séances de coaching intégrées. Transformez
        l'acquisition de connaissances en réussite professionnelle palpable.
      </p>
      <div className="formation-cells-container">
        {formationsData.map((formation, index) => (
          <FormationCell
            key={index}
            image={formation.image}
            title={formation.title}
            description={formation.description}
            id={formation.id}
            content={formation.content}
            type={"formation"}
          />
        ))}
      </div>
      <p style={{ padding: "40px 0px 10px 0px" }}>
        <i>
          Les indicateurs de performance seront retranscrits sur le site plus
          tard dans le temps
        </i>
      </p>
    </section>
  );
};

export default FormationsSection;
