import Value from "../../models/Value";
import {
  faAward,
  faBullseye,
  faEarListen
} from "@fortawesome/free-solid-svg-icons";

const valuesData: Value[] = [
  {
    icon: faEarListen,
    color: "blue",
    title: "Écoute",
    description:
      "Comprendre vos besoins pour apporter des solutions de formation optimales est notre vocation. Pour cela, nous réalisons un suivi personnalisé et continu. En amont Un audit est offert pour appréhender au mieux les besoins de l’organisation et mettre en place les actions & outils adéquats."
  },
  {
    icon: faAward,
    title: "Qualité",
    color: "green",
    description:
      "Des prestations de qualité et sur mesure réalisées par des experts et professionnels reconnus, nos associés cumulent plus de 50 années d’experience dans des environnements très variés."
  },
  {
    icon: faBullseye,
    title: "Ambition",
    color: "red",
    description:
      "Notre ambition : votre succès. Nous prenons à cœur notre mission : Vous aider à devenir meilleur. Nous déployons notre expertise de l’organisation pour analyser vos besoins et y répondre par des formations visant l’Excellence."
  }
];

export default valuesData;
