import { Routes, Route } from "react-router-dom";

import Home from "../pages/HomePage";
import Formation from "../pages/FormationPage";

const AppNavigator = () => {
  return (
    <Routes>
      <Route path="*" element={<Home />} />
      <Route path="formation/:id" element={<Formation />} />
      <Route path="bilan/:id" element={<Formation />} />
    </Routes>
  );
};

export default AppNavigator;
