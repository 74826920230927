import React from "react";
import "./styles.scss";

import AboutImage from "../../../assets/about.webp";

const AboutSection: React.FC = () => {
  return (
    <section className="about-section" id="a-propos">
      <h2>À propos</h2>
      <div className="about-section-container">
        <div className="about-section-left">
          <div
            className="about-section-img"
            style={{ backgroundImage: `url(${AboutImage})` }}
          ></div>
        </div>
        <div className="about-section-right">
          <div className="about-section-texts">
            <p>
              NATELCO, société créée depuis presque une décennie, est le fruit
              d’une longue réflexion et d’une analyse transversale des flux du
              circuit de l’information.
            </p>
            <p>
              Améliorer l’environnement, accompagner l’émulation de vos acteurs,
              former vos collaborateurs pour augmenter la productivité et la
              motivation, par des formateurs experts, pédagogiques et proches de
              vos problématiques sont nos vecteurs de création et
              d’amélioration.
            </p>
            <p>
              Augmenter les compétences de chacun avec un panel de formations
              varié, accompagner le changement, construire une cohésion d’équipe
              sont autant de savoir-faire de NATELCO que nous mettons à votre
              service.
            </p>
            <p>
              NATELCO, composée d’experts aide les individus, via le bilan de
              compétences, à faire le point sur leur carrière professionnelle,
              mieux cerner leur potentiel d’évolution, donner un nouvel élan à
              la vie professionnelle et être aligner avec soi même selon sa
              maturité.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
