import React from "react";
import "./styles.scss";
import coachingData from "../../../assets/data/coaching";

import FormationCell from "../../../components/FormationCell";

const CoachingSection: React.FC = () => {
  return (
    <section className="coaching-section" id="coaching">
      <h2>Coaching</h2>
      <p>
        Le coaching professionnel est un processus interactif et individualisé
        au cours duquel un coach qualifié guide et soutient une personne (le
        coaché) dans le développement de ses compétences, la réalisation de ses
        objectifs professionnels et la maximisation de son potentiel, en
        utilisant des techniques de questionnement, de réflexion et de
        planification.
      </p>
      <div className="formation-cells-container">
        {coachingData.map((coaching, index) => (
          <FormationCell
            key={index}
            image={coaching.image}
            title={coaching.title}
            description={coaching.description}
            id={coaching.id}
          />
        ))}
      </div>
      <p style={{ padding: "40px 0px 10px 0px" }}>
        <i>
          Les indicateurs de performance seront retranscrits sur le site plus
          tard dans le temps
        </i>
      </p>
    </section>
  );
};

export default CoachingSection;
