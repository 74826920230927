import React from "react";
import "./styles.scss";
import bilansData from "../../../assets/data/bilans";

import FormationCell from "../../../components/FormationCell";

const BilansSection: React.FC = () => {
  return (
    <section className="bilans-section" id="bilans">
      <h2>Bilans de carrière et de compétences</h2>
      <p>
        Les bilans de compétences sont de véritables outils de réflexion et de
        mise en mouvement des salariés dans un parcours professionnel qui
        aujourd’hui est constitué de plusieurs étapes. Que cela soit en début, à
        mi-carrière ou en dernière partie de carrière, différents « tournants »
        peuvent se présenter aux collaborateurs. Par obligation, car les
        environnements sont mouvants et incertains ou par envie voire lassitude,
        la nécessité de redéfinir et se projeter sur un nouveau projet
        professionnel se présente à tout salariés quelque soit son métier, son
        statut et son secteur d’activité.
      </p>
      <div className="formation-cells-container">
        {bilansData.map((bilan, index) => (
          <FormationCell
            key={index}
            image={bilan.image}
            title={bilan.title}
            description={bilan.description}
            id={bilan.id}
            content={bilan.content}
            type={"bilan"}
          />
        ))}
      </div>
      <p style={{ padding: "20px 0px 40px 0px" }}>
        <i>
          Les indicateurs de performance seront retranscrits sur le site plus
          tard dans le temps
        </i>
      </p>
    </section>
  );
};

export default BilansSection;
