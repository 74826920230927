import React from "react";
import "./styles.scss";
import optimizeData from "../../../assets/data/optimize";

import ValueCell from "../../../components/ValueCell";

const OptimizeSection: React.FC = () => {
  return (
    <section className="optimize-section" id="optimiser">
      <h2>Optimiser les financements</h2>
      <div className="optimize-section-container">
        {optimizeData.map((optimize, index) => (
          <ValueCell
            key={index}
            icon={optimize.icon}
            color={optimize.color}
            title={optimize.title}
            description={optimize.description}
          />
        ))}
      </div>
    </section>
  );
};

export default OptimizeSection;
