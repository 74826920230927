import React, { useState } from "react";
import "./styles.scss";

import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import Input from "../../../components/Input";
import Button from "../../../components/Button";

const ContactSection: React.FC = () => {
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [job, setJob] = useState("");
  const [email, setEmail] = useState("");
  const [object, setObject] = useState("");
  const [message, setMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = async () => {
    setIsLoading(true);
    try {
      const res = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE || "",
        process.env.REACT_APP_EMAILJS_TEMPLATE || "",
        {
          lastname: name,
          firstname: firstName,
          job: job,
          email: email,
          object: object,
          message: message
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY || ""
      );
      if (res.status === 200) {
        toast.success("Email envoyé!");
        setName("");
        setFirstName("");
        setJob("");
        setEmail("");
        setObject("");
        setMessage("");
      }
    } catch (err) {
      toast.error(
        "Une erreur est survenue, contactez-nous sur contact@natelco.fr"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="contact-section" id="contact">
      <h2>Contactez nous</h2>
      <p style={{ textAlign: "center", paddingBottom: "40px" }}>
        Pour toute demande de renseignement ou pour un premier contact, merci de
        remplis le formulaire suivant:
      </p>
      <div className="container">
        <div className="grid-lg-6">
          <Input
            label="Nom"
            placeholder="Nom"
            type="text"
            className="input-contact"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            label="Prénom"
            placeholder="Prénom"
            type="text"
            className="input-contact"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Input
            label="Fonction"
            placeholder="Fonction"
            className="input-contact"
            type="text"
            value={job}
            onChange={(e) => setJob(e.target.value)}
          />
          <Input
            label="Email"
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="grid-lg-6">
          <Input
            label="Objet"
            placeholder="Objet"
            className="input-contact"
            type="text"
            value={object}
            onChange={(e) => setObject(e.target.value)}
          />
          <Input
            label="Message"
            placeholder="Message"
            type="text"
            isTextArea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
      </div>
      <div className="contact-section-button">
        <Button
          disabled={
            !name || !firstName || !job || !email || !object || !message
          }
          loading={isLoading}
          onClick={sendEmail}
        >
          Envoyer
        </Button>
      </div>
    </section>
  );
};

export default ContactSection;
