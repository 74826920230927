import "./styles/App.scss";
import { BrowserRouter } from "react-router-dom";

import TopBar from "./components/TopBar";
import AppNavigator from "./navigation/App.navigator";
import Footer from "./components/Footer";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <TopBar />
      <div className="app-wrapper">
        <AppNavigator />
      </div>
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </BrowserRouter>
  );
}

export default App;
