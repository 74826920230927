import { useEffect, useState } from "react";
import "./styles.scss";
import { useLocation, useParams } from "react-router-dom";
import formationsData from "../../assets/data/formations";
import Formation from "../../models/Formation";
import bilansData from "../../assets/data/bilans";

const FormationPage = () => {
  const [formation, setFormation] = useState<Formation | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    let current_path = location.pathname.split("/")[1];
    let formationFound = null;
    if (current_path === "bilan") {
      formationFound = bilansData.find((element) => element.id === id);
    } else {
      formationFound = formationsData.find((element) => element.id === id);
    }

    setFormation(formationFound || null);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div style={{ height: "2000px" }}></div>;
  } else {
    if (formation) {
      return (
        <div className="formation">
          <h1>{formation?.title}</h1>
          <img
            alt="logo"
            src={formation?.image}
            style={{ borderRadius: "8px", margin: "20px 0px" }}
            className="img-responsive"
          />
          <h2>Programme de formation</h2>
          {formation?.content}
        </div>
      );
    } else {
      return (
        <div style={{ height: "2000px" }}>
          <p>Not found</p>
        </div>
      );
    }
  }
};

export default FormationPage;
