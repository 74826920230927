import Formation from "../../models/Formation";
import BilanDeCompetence from "../bilans-bilan-de-competence.webp";

const bilansData: Formation[] = [
  {
    image: BilanDeCompetence,
    title: "Bilan de compétences",
    description:
      "Les bilans de compétences sont des outils essentiels qui incitent les salariés à réfléchir et à progresser dans leur parcours professionnel, composé de multiples étapes distinctes.",
    id: "bilan-de-competence",
    content: (
      <div>
        <p>
          Le bilan de compétences est un dispositif individuel d'accompagnement
          visant à faciliter l'évolution professionnelle.
        </p>
        <p>
          Il permet d'analyser les compétences tant professionnelles que
          personnelles, ainsi que les aptitudes et motivations afin de définir
          un projet professionnel et éventuellement un projet de formation.
        </p>

        <h2>Public visé</h2>

        <p>Tout public</p>

        <h2>Prérequis</h2>

        <p>Aucun prérequis.</p>

        <p>
          Néanmoins, Le bilan demande au salarié engagé dans cette démarche une
          contribution personnelle importante par une réflexion et actions
          volontaristes de recherche d'information, guidée par un consultant
          spécialiste du conseil en orientation professionnelle et de carrière
        </p>

        <h2>Objectifs pédagogiques</h2>
        <ul>
          <li>
            Être capable d'identifier ses aspirations, ses valeurs, ses besoins,
            sa personnalité
          </li>
          <li>
            Pouvoir analyser ses compétences, ses ressources, ses savoirs et ses
            savoir-faire
          </li>
          <li>
            Savoir repérer sa valeur ajoutée afin de définir un projet
            professionnel
          </li>
          <li>
            Pouvoir développer son employabilité et sécuriser son parcours
          </li>
          <li>Être capable d'avoir une projection positive vers l'avenir</li>
          <li>Savoir mieux se positionner sur le marché de l'emploi</li>
          <li>Pouvoir s'orienter et piloter sa carrière de façon autonome</li>
          <li>Être capable de définir ou valider un projet professionnel</li>
        </ul>
        <h2>Durée et modalité d'organisation</h2>

        <p>18 heures</p>

        <p>
          Formation individuelle en présentiel, à distance ou de façon hybride
        </p>

        <h2>Lieux</h2>

        <p>
          La formation en présentiel est dispensée à Paris dans les bureaux mis
          à disposition par Natelco
        </p>

        <p>
          La formation est à distance, les rendez-vous sont organisés en
          fonction des disponibilités de l'apprenant.
        </p>

        <h2>Accessibilité</h2>

        <p>
          Les personnes en situation d'handicap, sont invitées à nous informer
          de leurs besoins lors de la validation de votre inscription ou dès que
          possible. Cela nous permettra d'ajuster le parcours de formation et le
          contact avec le formateur.
        </p>

        <h2>Délai d'accès</h2>

        <p>
          Le délai d'accès à la formation est de 15 jours après l'inscription.
        </p>

        <p>
          Après la validation de l'inscription l'apprenant est contacté sous 72
          heures par le formateur pour établir le planning de la formation.
        </p>

        <h2>Tarifs</h2>

        <p>Honoraire forfaitaire (financement entreprise) : 2 000€ H.T</p>

        <p>Honoraire forfaitaire (financement CPF) : 2 600€ T.T.C</p>

        <h2>Déroulé/contenu de la formation</h2>

        <p>
          Entretien préalable à la réalisation du bilan (gratuit et sans
          engagement)
        </p>

        <ul>
          <li>
            Initier un contact avec le consultant qui sera l'interlocuteur
            durant le bilan
          </li>
          <li>Clarifier les attentes du bénéficiaire</li>
          <li>
            Informer sur le déroulement total du bilan (informations pratiques
            et légales)
          </li>
          <li>Établir un devis de prestation</li>
        </ul>

        <h3>A) Une phase préliminaire qui a pour objet</h3>

        <ul>
          <li>Confirmer l'engagement du bénéficiaire dans sa démarche</li>
          <li>Définir et d'analyser la nature de ses besoins</li>
          <li>
            Informer des conditions de déroulement du bilan de compétences,
            ainsi que des méthodes et techniques mises en œuvre
          </li>
        </ul>
        <p>
          La phase préliminaire permet d'analyser la demande et de fixer les
          objectifs personnalises du bilan : il s'agit de concevoir un projet de
          bilan en adéquation avec la situation, les attentes et les motivations
          du candidat. Un contrat pédagogique fixant les objectifs personnalises
          du bilan est établi conjointement avec le bénéficiaire. Il aura valeur
          de référence sur toute la durée du bilan
        </p>

        <h3>B) Une phase d'investigation permettant au bénéficiaire</h3>

        <ul>
          <li>
            d'analyser ses motivations et intérêts professionnels et personnels,
          </li>

          <li>
            d'identifier ses compétences et aptitudes professionnelles et
            personnelles et le cas échéant, d'évaluer ses connaissances
            générales
          </li>

          <li>de déterminer ses possibilités d'évolution professionnelle.</li>
        </ul>
        <h3>
          C) Une phase de conclusion qui, par la voie d'entretiens
          personnalises, permet au bénéficiaire :
        </h3>
        <ul>
          <li>
            de prendre connaissance des résultats détailles de la phase
            d'investigation
          </li>

          <li>
            de recenser les facteurs susceptibles de favoriser ou non la
            réalisation d'un projet professionnel et le cas échéant, d'un projet
            de formation
          </li>

          <li>
            de prévoir les principales étapes de la mise en œuvre de ce projet.
          </li>
        </ul>
        <h3>D) Conclusion</h3>

        <p>
          La phase de conclusion du bilan de compétences, se termine par la
          présentation au bénéficiaire du document de synthèse. Le document de
          synthèse est élaboré pendant la phase de conclusion du bilan de
          compétences.
        </p>

        <p>Il comporte les indications suivantes :</p>

        <li>Analyse des axes de progrès et développement</li>
        <li>Détermination d'objectifs et plan d'action</li>
        <li>Analyse du marché de l'emploi</li>
        <li>
          Validation du projet Travail guidé et formalisation de la synthèse
        </li>
        <li>
          Rédaction de la synthèse et du plan d'action et remise du document de
          synthèse
        </li>
        <li>Un bilan est réalisé au bout de 6 mois.</li>

        <h2>Moyen d'encadrement</h2>

        <p>
          Le consultant accompagne le processus en mode conseil et coaching pour
          aider à initier l'action et accompagner le salarié à la dynamique de
          changement. Il est neutre et bienveillant, à la fois témoin et miroir,
          dans un processus d'écoute active
        </p>

        <p>Nous mettons à disposition les CV sur demande.</p>

        <h2>Méthodes mobilisées</h2>

        <p>Formation individuelle</p>

        <p>
          Rendez-vous organisés en fonction des disponibilités de l'apprenant.
        </p>

        <p>
          Support pédagogique recensant l'ensemble des démarches effectuées.
        </p>

        <p>Co-rédaction d'une synthèse.</p>

        <p>Tests spécialisés.</p>

        <p>Si Entretiens à distance : Téléphone ou Visio</p>

        <p>Questionnaire d'évaluation et d'autoévaluation</p>

        <h2>Modalités de suivi et d'évaluation</h2>

        <p>
          A l'issue du bilan, il sera remis à la personne bénéficiant du bilan
          de compétences, un rapport de fin d'accompagnement dans lequel elle
          retrouvera l'ensemble des outils et éléments constituant les
          recherches et la progression pour l'élaboration du projet
          professionnel ou projet de formation.
        </p>

        <p>Feuille d'émargement et questionnaire de satisfaction.</p>

        <h2>Cadre règlementaire</h2>

        <p>
          Le bilan de compétences est une démarche individuelle, encadrée
          juridiquement par la Loi du 31 Décembre 1991 (R. 6322-35), la Loi n°
          2018-771 du 05 Septembre 2018 « pour la liberté́ de choisir son avenir
          professionnel », et donc les articles L6313-1, L 6313-4 et R 6313-4 à
          R6313- 7 du Code du Travail.
        </p>

        <p>
          Ce cadre définit entre autres les trois phases indispensables à la
          réalisation du bilan et leur contenu nécessaire indiqués dans le
          déroulé/contenu de la formation.
        </p>

        <h3>Déontologie :</h3>

        <p>
          L'organisme de formation s'engage à respecter les obligations
          déontologiques fixées par la règlementation définies ci-dessous :
        </p>

        <ul>
          <li>Respecter le consentement du bénéficiaire</li>
          <li>
            Ne pas faire de prosélytisme sur le bilan de compétences lors de
            l'entretien d'accueil et de présentation du cabinet.
          </li>
          <li>
            Établir une convention tripartite précisant notamment la nature des
            informations transmises au prescripteur.
          </li>
          <li>
            Respecter le secret professionnel (les personnes chargées de
            réaliser et de détenir les bilans de compétences sont soumises aux
            dispositions de l'art des articles 226-13 et 226-14 du code pénal en
            ce qui concerne les informations qu'elles détiennent)
          </li>
          <li>
            La nature et la teneur des investigations menées par le prestataire
            : les informations demandées au bénéficiaire doivent présenter un
            lien direct avec l'objet du bilan de compétences. Le bénéficiaire
            est tenu d'y répondre de bonne foi, des lors que les questions
            remplissent les conditions précédemment citées.
          </li>
          <li>
            Le bilan doit être organisé en 3 phases identifiables (Préliminaire,
            Investigation et Conclusion)
          </li>
          <li>
            Notion de propriété du bilan : le bénéficiaire est seul destinataire
            des résultats du document de synthèse. Les résultats totaux ou
            partiels sont conditionnés à l'autorisation du bénéficiaire et sont
            définis dans la convention tripartite
          </li>
          <li>
            L'intégralité des résultats du bilan de compétences doit être
            restituée au bénéficiaire
          </li>
          <li>
            Le document de synthèse est établi par le prestataire sous sa seule
            responsabilité́. Il doit entrer présenté avant sa rédaction finale,
            au bénéficiaire pour d'éventuelles modifications.
          </li>
          <li>Le recours à des méthodes/techniques fiables</li>
          <li>
            Détruire l'intégralité des documents à l'issue du Bilan « Les
            documents élabores pour la réalisation d'un bilan de compétences
            sont aussitôt détruits par l'organisme prestataire, toutefois le
            document de synthèse pourra entre conservé sur demande écrite du
            bénéficiaire fondée sur la nécessité d'un suivi de sa situation ;
            dans cette hypothèse, ils ne pourront être gardés plus d'un an (art
            R. 6313-7 et L. 6313-4 du code du travail).
          </li>
        </ul>
      </div>
    )
  }
];

export default bilansData;
