import React from "react";
import "./styles.scss";
import valuesData from "../../../assets/data/values";

import ValueCell from "../../../components/ValueCell";

const ValuesSection: React.FC = () => {
  return (
    <section className="values-section" id="nos-valeurs">
      <h2>Nos valeurs</h2>
      <div className="values-section-container">
        {valuesData.map((value, index) => (
          <ValueCell
            key={index}
            icon={value.icon}
            color={value.color}
            title={value.title}
            description={value.description}
          />
        ))}
      </div>
    </section>
  );
};

export default ValuesSection;
