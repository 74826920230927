import "./styles.scss";

import HomeSection from "./HomeSection";
import AboutSection from "./AboutSection";
import ValuesSection from "./ValuesSection";
import FormationsSection from "./FormationsSection";
import OptimizeSection from "./OptimizeSection";
import ContactSection from "./ContactSection";

import BilansSection from "./BilansSection";
import CoachingSection from "./CoachingSection";

const HomePage = () => {
  return (
    <div className="home">
      <HomeSection />
      <AboutSection />
      <ValuesSection />
      <FormationsSection />
      <BilansSection />
      <CoachingSection />
      <OptimizeSection />
      <ContactSection />
    </div>
  );
};

export default HomePage;
