import React from "react";
import clsx from "clsx";

import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

export interface ButtonProps {
  color?: string;
  className?: string;
  outline?: boolean;
  disabled?: boolean;
  loading?: boolean;

  children: React.ReactNode;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  color = "blue",
  className,
  outline,
  disabled,
  loading,
  children,
  onClick
}) => {
  const baseClass = "ui-button";

  const rootClass = clsx(
    baseClass,
    color && `${baseClass}-${color}`,
    (disabled || loading) && `${baseClass}-disabled`,
    outline && `${baseClass}-${color}-outline`,
    className
  );

  return (
    <a className={rootClass} onClick={onClick}>
      {loading && (
        <FontAwesomeIcon
          icon={faCircleNotch}
          spin
          style={{ marginRight: "10px" }}
        />
      )}
      {children}
    </a>
  );
};

export default Button;
