import Value from "../../models/Value";
import {
  faGraduationCap,
  faUser,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";

const optimizeData: Value[] = [
  {
    icon: faUserTie,
    title: "La formation professionnelle, pourquoi ?",
    description:
      "Pour faire évoluer les compétences des salariés aux nouveaux besoins. Pour optimiser les atouts du groupe. Pour faire des Ressources Humaines un facteur clé de compétitivité. Pour aligner les intérêts des salariés sur ceux de la structure. Pour renforcer la cohésion d’équipe."
  },
  {
    icon: faGraduationCap,
    title: "La formation professionnelle, financée par les OPCA",
    description:
      "Depuis la Réforme 2014, C’est une obligation de cotiser pour la formation de vos collaboratuers auprès de votre OPCA (Organisme Paritaire Collecteur Agréé). C’est votre droit d’utiliser ce fonds pour répondre aux besoins en formation. Ainsi, vos formations peuvent etre financees integralement par les OPCA. Vous n'aurez rien à debourser. Et tout à y gagner."
  },
  {
    icon: faUser,
    title: "Salariés, Particuliers, votre CPF",
    description:
      "NATELCO vous propose un accompagnement personnalisé et confidentiel et vous apporte également une aide dans le montage de votre dossier de demande de financement CPF."
  }
];

export default optimizeData;
