import React from "react";
import "./styles.scss";
import Value from "../../models/Value";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ValueCell: React.FC<Value> = ({
  icon,
  color = "blue",
  title,
  description
}) => {
  return (
    <div className="value-cell">
      <div
        className="value-cell-icon-container"
        style={{
          backgroundColor: `var(--${color}-a1)`
        }}
      >
        <FontAwesomeIcon icon={icon} color={`var(--${color})`} />
      </div>
      <h3 style={{ color: `var(--${color})` }}>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default ValueCell;
