import React from "react";
import "./styles.scss";
import dayjs from "dayjs";

const Footer: React.FC = () => {
  return (
    <section className="footer">
      <div className="footer-container">
        <div className="footer-container-main-cell">
          <h3>Natelco – Les Ateliers de la Connaissance</h3>
          <p>Organisme de formation agrée par l’état</p>
          <p>RCS Paris : B 818585077 00014</p>
          <p>Numéro d’agrément : 11 75 54 55 475</p>
        </div>
        <div className="footer-container-cell">
          <h3>Contact</h3>
          <p>
            <a className="footer-link" href="mailto:contact@natelco.fr">
              contact@natelco.fr
            </a>
          </p>
          <p>
            <a className="footer-link" href="tel:0660478556">
              06 60 47 85 56
            </a>
          </p>
        </div>
        <div className="footer-container-cell">
          <h3>Nous rejoindre</h3>
          <p>
            <a className="footer-link" href="mailto:contact@natelco.fr">
              contact@natelco.fr
            </a>
          </p>
        </div>
      </div>
      <div className="footer-copyright">
        <h3>Copyright © {dayjs().year()} Natelco. All Rights Reserved.</h3>
      </div>
    </section>
  );
};

export default Footer;
