import React from "react";
import "./styles.scss";

import * as Scroll from "react-scroll";
import Button from "../../../components/Button";
import HomeImage from "../../../assets/home.webp";

const HomeSection: React.FC = () => {
  return (
    <section className="home-section">
      <div className="home-section-container">
        <div className="home-section-left">
          <div className="home-section-texts">
            <h1>
              Nourrissez vos compétences, faites{" "}
              <span>évoluer votre carrière</span>
            </h1>
            <p>
              Notre société accompagne les personnes et les entreprises dans
              leurs transitions et leur progression. Notre société créée depuis
              presque une décennie, est le fruit d’une longue réflexion et d’une
              analyse transversale des flux du circuit de l’information.
            </p>
            <Button
              className="home-section-button"
              onClick={() =>
                Scroll.scroller.scrollTo("contact", {
                  offset: -60,
                  smooth: true
                })
              }
            >
              Nous contacter
            </Button>
          </div>
        </div>
        <div className="home-section-right">
          <div
            className="home-section-img"
            style={{ backgroundImage: `url(${HomeImage})` }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default HomeSection;
