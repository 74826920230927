import "./styles.scss";
import logo from "../../assets/logo.webp";

import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import * as Scroll from "react-scroll";
import Button from "../Button";

const TopBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    window
      .matchMedia("(min-width: 700px)")
      .addEventListener("change", (e) => setToggleDropdown(false));
  }, []);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const NavLinks = () => {
    let links = [
      { title: "À Propos", link: "a-propos" },
      { title: "Formations", link: "formations" },
      { title: "Bilan de Compétences", link: "bilans" },
      { title: "Coaching", link: "coaching" }
    ];

    let nav_links: React.ReactNode;

    nav_links = links.map((link, index) => {
      if (currentPath === "/") {
        return (
          <Scroll.Link
            key={index}
            offset={-60}
            spy
            hashSpy
            smooth
            activeClass="topbar-link-container--active"
            className="topbar-link-container"
            to={link.link}
          >
            <p className="topbar-link">{link.title}</p>
          </Scroll.Link>
        );
      } else {
        return (
          <NavLink
            key={index}
            className="topbar-link-container"
            onClick={() => {
              setTimeout(() => {
                Scroll.scroller.scrollTo(link.link, {
                  offset: -60,
                  smooth: true
                });
              }, 750);
            }}
            to="/"
          >
            <p className="topbar-link">{link.title}</p>
          </NavLink>
        );
      }
    });

    return nav_links;
  };

  return (
    <nav className="topbar">
      <div className="topbar-container">
        <Link
          className="topbar-brand"
          to="/"
          onClick={() => setToggleDropdown(false)}
        >
          <img
            alt="logo"
            src={logo}
            className="img-fluid"
            style={{ height: "34px" }}
          />
          <p className="topbar-brand-name">
            <span>N</span>atelco
          </p>
        </Link>
        <div className="topbar-links">
          {NavLinks()}
          <Button
            onClick={() => {
              if (currentPath === "/") {
                Scroll.scroller.scrollTo("contact", {
                  offset: -60,
                  smooth: true
                });
              } else {
                navigate("/");
                setTimeout(() => {
                  Scroll.scroller.scrollTo("contact", {
                    offset: -60,
                    smooth: true
                  });
                }, 750);
              }
            }}
          >
            Nous contacter
          </Button>
        </div>

        <div className="topbar-buttons-mobile">
          <div
            className="topbar-dropdown-indicator"
            onClick={() => setToggleDropdown(!toggleDropdown)}
          >
            <FontAwesomeIcon icon={faBars} />
          </div>
        </div>
      </div>
      {toggleDropdown ? (
        <div className="dropdown-container">
          <div className="topbar-links-mobile">{NavLinks()}</div>
        </div>
      ) : null}
    </nav>
  );
};

export default TopBar;
